import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout.js'

import WipStyles from '../css/pages/wip-styles'

const CLIPBOARD_MESSAGES = {
  succeeded: 'Copied to clipboard',
  failed: 'Could not copy to clipboard',
}

const query = graphql`
  query {
    strapiWipHome {
      sections
    }
  }
`

const WorkInProgressPage = () => {
  function renderHeader(section) {
    return (
      <section className="wip__header" key={section.strapi_component}>
        <img
          src={section.logo.url}
          className="wip__header-image"
          alt="NTS Work In Progress logo" />

        <h1 className="wip__header-heading">
          {section.heading}
        </h1>

        <div
          className="wip__header-paragraph"
          dangerouslySetInnerHTML={{ __html: section.subheading.replace(/\n/g, '<br />') }} />
      </section>
    )
  }

  function renderAbout(section) {
    return (
      <section className="wip__section wip__section--about" key={section.strapi_component}>
        <div className='wip__section-wrapper'>
          <h2 className='wip__section-heading'>
            {section.heading}
          </h2>

          <div
            className="wip__paragraph"
            dangerouslySetInnerHTML={{ __html: section.copy.replace(/\n/g, '<br />') }} />


          <div className="wip__about-button-container">
            <a
              href={section.button_url}
              className="nts-button nts-button--alt wip__button wip__button--primary nts-app">
              {section.button_label}
            </a>
          </div>
        </div>
      </section>
    )
  }

  function renderNominees(section) {
    return (
      <section className="wip__section wip__section--nominees" key={section.strapi_component}>
        <div className='wip__section-wrapper'>
          <h2 className='wip__section-heading'>
            {section.heading}
          </h2>

          {!!section.buttons.length &&
            <div className="wip__nominees-button-group">
              {section.buttons.map((button, index) => {
                return (
                  <a
                    href={button.link_url}
                    className="nts-button nts-button--alt wip__button wip__button--secondary nts-app"
                    key={index}>
                    {button.label}
                  </a>
                )
              })}
            </div>
          }

          <div
            className="wip__paragraph"
            dangerouslySetInnerHTML={{ __html: section.copy.replace(/\n/g, '<br />') }}
            style={{ maxWidth: '574px', margin: '0 auto' }} />

          <div className="wip__share">
            <span className="wip__share-label">Share</span>
            {section.facebook_link &&
              <a
                className="wip__share-link"
                href={section.facebook_link}
                target="_blank"
                rel="noreferrer"
                data-track="event"
                data-category="WorkInProgress"
                data-target="ShareToFacebook"
                data-origin="fromWipPageBottom">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="20"
                  fill="#CCC">
                  <path
                    fillRule="evenodd"
                    d="M12 3.467H8.592c-.224 0-.424.11-.6.333-.176.222-.264.496-.264.822V6.89H12v3.289H7.728V20h-4.08v-9.822H0v-3.29h3.648v-1.91c0-1.393.472-2.57 1.416-3.534C6.008.481 7.184 0 8.592 0H12v3.467z" />
                </svg>
              </a>
            }

            {section.twitter_link &&
              <a
                className="wip__share-link"
                href={section.twitter_link}
                target="_blank"
                rel="noreferrer"
                data-track="event"
                data-category="WorkInProgress"
                data-target="ShareToTwitter"
                data-origin="fromWipPageBottom">
                <svg
                  style={{ marginRight: '-4px' }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="16"
                  fill="#CCC">
                  <path
                    fillRule="evenodd"
                    d="M22 1.887c-.574.773-1.323 1.473-2.248 2.102v.515c0 1.859-.478 3.646-1.435 5.362-.956 1.716-2.439 3.167-4.447 4.354C11.86 15.407 9.55 16 6.935 16 4.384 16 2.072 15.4 0 14.198c.223.029.59.043 1.1.043 2.104 0 3.97-.572 5.596-1.716-.989-.028-1.866-.3-2.63-.815-.766-.514-1.292-1.186-1.579-2.016.16.058.438.086.837.086.398 0 .789-.043 1.172-.129-1.053-.2-1.913-.672-2.583-1.415C1.243 7.492.909 6.634.909 5.662V5.62c.574.286 1.243.458 2.008.515C1.578 5.304.91 4.175.91 2.745c0-.686.207-1.358.621-2.016 2.456 2.688 5.564 4.09 9.327 4.204a2.581 2.581 0 0 1-.144-.9c0-1.116.438-2.067 1.315-2.853C12.905.393 13.99 0 15.28 0c1.292 0 2.368.415 3.229 1.244A11.242 11.242 0 0 0 21.379.3c-.32.944-.973 1.687-1.962 2.23A11.302 11.302 0 0 0 22 1.888z" />
                </svg>
              </a>
            }

            {section.web_link &&
              <button
                id="WipShareLinkWeb"
                className="wip__share-link wip__share-link--copy-clipboard"
                data-track="event"
                data-category="WorkInProgress"
                data-target="ShareByCopyLink"
                data-origin="fromWipPageBottom">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="#CCC">
                  <g fillRule="evenodd">
                    <path fill="none" d="M-13-12h43.448v42H-13z"/>
                    <path d="M13.235 2.118c-.294 0-.544.103-.75.308l-2.294 2.295a1.021 1.021 0 0 0-.309.75c0 .308.118.573.353.794l.204-.21c.114-.118.193-.197.237-.237a2.66 2.66 0 0 1 .21-.165.887.887 0 0 1 .585-.182c.294 0 .544.103.75.308.205.206.308.456.308.75a.887.887 0 0 1-.182.585c-.07.096-.125.165-.165.21-.04.044-.12.123-.237.237l-.21.204c.228.242.497.364.805.364.295 0 .545-.103.75-.31l2.284-2.271a1.02 1.02 0 0 0 .297-.75c0-.294-.095-.544-.286-.75l-1.61-1.622a1.008 1.008 0 0 0-.74-.308zM5.46 9.87c-.295 0-.545.103-.75.31l-2.284 2.271a1.021 1.021 0 0 0-.308.75c0 .287.099.537.297.75l1.61 1.622c.207.205.453.308.74.308.294 0 .544-.103.75-.308l2.294-2.295c.198-.198.298-.448.298-.75 0-.308-.114-.573-.342-.794l-.204.21a6.821 6.821 0 0 1-.237.237 2.66 2.66 0 0 1-.21.165.887.887 0 0 1-.585.182c-.294 0-.544-.103-.75-.308a1.021 1.021 0 0 1-.308-.75.887.887 0 0 1 .182-.585c.07-.096.125-.165.165-.21.04-.044.12-.123.237-.237l.21-.204a1.07 1.07 0 0 0-.805-.364zM10.985.926A3.063 3.063 0 0 1 13.235 0c.883 0 1.629.312 2.24.938l1.61 1.62c.61.611.915 1.357.915 2.24 0 .89-.312 1.64-.938 2.25L14.78 9.32c-.61.61-1.356.915-2.239.915a3.102 3.102 0 0 1-2.305-.97l-.97.97c.647.633.97 1.397.97 2.294 0 .883-.309 1.633-.926 2.25l-2.294 2.295a3.063 3.063 0 0 1-2.25.926c-.883 0-1.629-.312-2.24-.938l-1.61-1.62C.305 14.83 0 14.084 0 13.201c0-.89.312-1.64.938-2.25L3.22 8.68c.61-.61 1.356-.915 2.239-.915.904 0 1.672.323 2.305.97l.97-.97c-.647-.633-.97-1.397-.97-2.294 0-.883.309-1.633.926-2.25L10.985.926z" />
                  </g>
                </svg>

                <input
                  id="WipShareWebInput"
                  type="text"
                  className="visuallyhidden"
                  value={section.web_link}
                  readOnly />

                <span
                  id="WipShareCopyMessage"
                  className="wip__share-copy-message">
                  {CLIPBOARD_MESSAGES.succeeded}
                </span>
              </button>
            }
          </div>
        </div>
      </section>
    )
  }

  function renderSponsors(section) {
    return (
      <section
        className="wip__section wip__section--sponsors"
        key={section.strapi_component}>
        {!!section.wip_sponsors_block.length && (
          <div className="wip__sponsors-container">
            <div className="wip__sponsors wip__sponsors--primary">
              {section.wip_sponsors_block.slice(0, 2).map((sponsor, index) => {
                return (
                  <a
                    className="wip__sponsor-link"
                    href={sponsor.link_url}
                    target="_blank"
                    rel="noreferrer"
                    key={index}>
                    {sponsor.logo &&
                      <img
                        className="wip__sponsor-logo"
                        src={sponsor.logo.url}
                        alt={sponsor.name}
                        style={{ maxWidth: `${sponsor.logo_width}px` }} />
                    }
                  </a>
                )
              })}
            </div>
            <div className="wip__sponsors wip__sponsors--secondary">
              {section.wip_sponsors_block.slice(2).map((sponsor, index) => {
                return (
                  <a
                    className="wip__sponsor-link"
                    href={sponsor.link_url}
                    target="_blank"
                    rel="noreferrer"
                    key={index}>
                    {sponsor.logo &&
                        <img
                          className="wip__sponsor-logo"
                          src={sponsor.logo.url}
                          alt={sponsor.name}
                          style={{ maxWidth: `${sponsor.logo_width}px` }} />
                    }
                  </a>
                )
              })}
            </div>
          </div>
        )}

        {section.notes &&
          <footer
            className="wip__sponsors-footer"
            dangerouslySetInnerHTML={{ __html: section.notes.replace(/\n/g, '<br />') }} />
        }
      </section>
    )
  }

  return (
    <>
      <StaticQuery
        query={query}
        render={data => {
          const sections = data.strapiWipHome.sections
          if (!sections.length) return null

          return (
            <Layout>
              <style>{WipStyles}</style>

              <main className="wip">
                {sections.map((section) => {
                  switch(section.strapi_component) {
                  case 'wip.hero':
                    return renderHeader(section)
                  case 'wip.wip-home-about':
                    return renderAbout(section)
                  case 'wip.wip-home-nominees':
                    return renderNominees(section)
                  case 'wip.wip-home-sponsors':
                    return renderSponsors(section)
                  default:
                    return null
                  }
                })}
              </main>
            </Layout>
          )
        }}
      />
    </>
  )
}

export default WorkInProgressPage
