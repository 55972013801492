
const WipStyles = `
  :root {
    --wip-accent-color: #e161b9;
    --wip-primary-button-hover-color: #9d4381;
  }

  .wip {
    background-color: var(--nts-black);
    color: white;
    margin-bottom: 86px;
    min-height: 100vh;
  }

  .wip__section {
    margin: 64px 0;
    padding: 0 24px;
  }

  .wip__header {
    background-color: var(--wip-accent-color);
    color: var(--nts-white);
    font-size: 34px;
    line-height: 36px;
    margin-bottom: 56px;
    padding: 48px 24px;
    padding-top: 96px;
    text-align: center;
  }

  .wip__header-image {
    height: 200px;
    width: 200px;
    filter: invert(100%);
  }

  .wip__header-heading {
    font-family: var(--font-primary);
    font-size: 38px;
    font-style: normal;
    font-weight: bold;
    line-height: 38px;
    margin: 36px 0 24px;
    text-transform: uppercase;
  }

  .wip__header-paragraph {
    font-family: var(--font-primary);
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    line-height: 28px;
    margin: 0;
    text-transform: uppercase;
  }

  .wip__section-wrapper {
    max-width: 700px;
    margin: 0 auto;
  }

  .wip__section--nominees .wip__section-wrapper {
    border-bottom: 3px solid var(--nts-white);
    padding-bottom: 60px;
  }

  .wip__section--sponsors {
    padding: 0 12px;
  }

  .wip__section-heading {
    font-family: var(--font-primary);
    font-size: 28px;
    font-style: normal;
    font-weight: bold;
    line-height: 32px;
    margin: 0;
    margin-bottom: 48px;
    text-align: center;
    text-transform: uppercase;
  }

  .wip__section-heading--nominees {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 32px;
  }

  .wip__paragraph {
    font-family: var(--font-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 21px;
    margin-bottom: 64px;
  }

  .wip__about-button-container {
    margin-bottom: 64px;
    text-align: center;
  }

  .nts-button.wip__button {
    font-family: var(--font-primary);
    font-size: 28px;
    font-style: normal;
    font-weight: bold;
    line-height: 32px;
    text-transform: uppercase;
    border: 2px solid var(--wip-accent-color);
    display: inline-block;
    line-height: 18px;
    padding: 30px 42px;
    text-decoration: none;
  }

  .nts-button.wip__button--primary {
    border: none;
    color: var(--nts-white);
    background-color: var(--wip-accent-color);
  }

  .nts-button.wip__button--primary:hover,
  .nts-button.wip__button--primary:focus {
    background-color: var(--wip-primary-button-hover-color);
    color: var(--nts-white) !important;
  }

  .wip__nominees-button-group {
    margin-bottom: 60px;
  }

  .nts-button.wip__button--secondary {
    background-color: var(--nts-black);
    color: var(--wip-accent-color);
    display: block;
    margin: 0 auto;
    margin-bottom: 24px;
    max-width: 318px;
    padding: 30px 48px;
    text-align: center;
  }

  .nts-button.wip__button--secondary:hover,
  .nts-button.wip__button--secondary:focus {
    background-color: var(--nts-black);
    border-color: var(--wip-primary-button-hover-color);
    color: var(--wip-primary-button-hover-color);
  }

  .wip__share {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 60px;
  }

  .wip__share-label {
    color: var(nts-grey-light);
    font-family: var(--font-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 21px;
    text-transform: uppercase;
  }

  .wip__share-link {
    margin-left: 24px;
    padding: 0;
    text-decoration: none;
  }

  .wip__share-link:hover svg,
  .wip__share-link:focus svg {
    fill: var(--nts-white);
  }

  .wip__sponsors-container {
    margin-bottom: 32px;
  }

  .wip__sponsors {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .wip__sponsors--primary {
    margin-bottom: 16px;
  }

  .wip__sponsor-link {
    max-width: 75%;
    padding: 24px 16px;
    text-align: center;
    width: 100%;
  }

  .wip__sponsor-logo {
    width: 100%;
  }

  .wip__sponsors--secondary .wip__sponsor-link {
    max-width: 50%;
    width: 50%;
  }

  .wip__sponsors-footer {
    font-family: var(--font-primary);
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    line-height: 18px;
    color: var(nts-grey-light);
    display: block;
    margin: 0 auto;
    max-width: 575px;
    padding: 12px 24px;
    text-align: center;
  }

  .wip__share-link--copy-clipboard {
    position: relative;
  }

  .wip__share-copy-message {
    animation: fade-out 1s cubic-bezier(0.2, 0, 0.5, 1) 2s;
    animation-fill-mode: forwards;
    background-color: var(--nts-white);
    border-radius: 2px;
    color: var(--nts-black);
    display: none;
    font-family: var(--font-primary);
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    left: calc(100% + 24px);
    line-height: 18px;
    padding: 4px 8px;
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: -3px;
    width: 10em;
  }

  @keyframes fade-out {
    from { opacity: 1; }
    to { opacity: 0; }
  }

  @media(min-width: 768px) {
    .wip__header {
      margin-bottom: 96px;
      padding: 48px;
      padding-top: 111px;
    }

    .wip__header-image {
      height: 233px;
      width: 233px;
    }

    .wip__header-heading {
      font-size: 52px;
      line-height: 58px;
    }

    .wip__header-paragraph {
      font-size: 34px;
      line-height: 36px;
    }

    .wip__section--about .wip__section-wrapper {
      border-bottom: 1px solid var(--nts-white);
      padding: 0 64px;
    }

    .wip__section-heading {
      font-size: 38px;
      line-height: 38px;
      margin-bottom: 32px;
    }

    .wip__section--nominees .wip__section-heading {
      font-size: 24px;
      line-height: 28px;
    }

    .wip__paragraph {
      font-size: 22px;
      line-height: 28px;
    }

    .wip__nominees-button-group {
      display: flex;
      justify-content: center;
    }

    .nts-button.wip__button--secondary {
      margin: 0 8px;
    }

    .wip__sponsors-container {
      margin-bottom: 16px;
    }

    .wip__sponsor-link,
    .wip__sponsors--secondary .wip__sponsor-link {
      width: auto;
    }

    .wip__sponsors--primary .wip__sponsor-link {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  @media(min-width: 1025px) {
    .wip__header {
      padding: 96px 48px;
    }
  }
`

export default WipStyles
